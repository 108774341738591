import * as XLSX from 'xlsx';

import { readBinaryFileAsync } from 'utils/file';

export const readXlsFileAsync = async file => {
  try {
    const fileData = await readBinaryFileAsync(file);

    const workbook = XLSX.read(fileData, { type: 'binary' });

    const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];

    const worksheet =
      workbook.SheetNames.length > 0 && workbook?.Sheets.Sheet1
        ? workbook.Sheets.Sheet1
        : firstWorksheet;

    return worksheet
      ? XLSX.utils
          .sheet_to_json(worksheet, { header: 1 })
          .filter((row, i, array) => {
            array[0].forEach((header, j) => {
              row[j] = row[j] === undefined ? '' : row[j];
            });
            return row.length > 0;
          })
      : null;
  } catch (error) {
    return null;
  }
};
